import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="site-container not-found-page">
      <div className="container text-center">
        <h1>ページを見つけることができませんでした</h1>
        <p>
          サイト内を隅々までくまなく探しました。<br />
            しかし、ご所望のページを見つけることができませんでした。<br />
              誠にかたじけないです。
        </p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
